import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    timeout: Number
  };
  static targets = ['submitButton'];

  connect() {
    this.startRefresh();
    document.addEventListener('turbo:submit-start', this.advanceParams);
    document.addEventListener('turbo:submit-start', this.resizeSpinnerContainer);
    document.addEventListener('click', this.removeDropdowns);
  }

  // Add the parameters of the form submission to the URL
  advanceParams(event) {
    window.history.replaceState({}, '', event.detail.formSubmission.location);
    window.location.reload();
  }
  resizeSpinnerContainer() {
    let reportsList = document.querySelector('#reports_list');
    let spinnerContainer = document.querySelector('#spinner-container');
    let dimensions = reportsList.getBoundingClientRect();
    if (spinnerContainer) {
      spinnerContainer.style.width = `${dimensions.width}px`;
      spinnerContainer.style.height = `${dimensions.height}px`;
    }
  }

  startRefresh() {
    if (this.timeoutValue > 0) {
      this.timeout = setTimeout(() => {
        this.element.requestSubmit();
      }, this.timeoutValue);
    }
  }

  stopRefresh() {
    clearTimeout(this.timeout);
  }

  enableSubmit() {
    this.submitButtonTargets.forEach((button) => {
      button.disabled = false;
      button.classList.remove('disabled');
    });
  }

  disableSubmit() {
    this.submitButtonTargets.forEach((button) => {
      button.disabled = true;
      button.classList.add('disabled');
    });
  }

  // When clicking outside of a dropdown, hide the dropdown.
  removeDropdowns(event) {
    document.querySelectorAll('.dropdown--container.show').forEach(function(element) {
      if (!element.contains(event.target)) {
        element.classList.remove('show');
      }
    });
  }

  disconnect() {
    this.stopRefresh();
    // Clear the removeDropdownEvent event listener
    window.removeEventListener('click', this.removeDropdowns);
    document.removeEventListener('turbo:submit-start', this.resizeSpinnerContainer);
    document.removeEventListener('turbo:submit-start', this.advanceParams);
  }
}
