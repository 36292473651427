import { Controller } from '@hotwired/stimulus';
import 'select2/dist/js/select2.full';

export default class extends Controller {
  static values = {
    multiple: Boolean,
    ajax: Boolean
  }

  connect() {
    if(this.ajaxValue && this.multipleValue) {
      this.initMultipleWithAjax();
    } else {
      if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iP(a|o)d/i)) {
        $(this.element).select2({
          width: 'resolve'
        });
      } else {
        const options = {
          minimumResultsForSearch: 10,
          containerCssClass: ':all:'
        };

        // Check if the element has the select_with_flags class
        if (this.element.classList.contains('select_with_flags')) {
          options.templateResult = this.formatLocaleWithFlags.bind(this);
          options.templateSelection = this.formatLocaleWithFlags.bind(this);
        }
        $(this.element)
          .select2(options)
          .trigger('change')
          .on('select2:select', function () {
            let event = new Event('change', { bubbles: true }); // fire a native event
            this.dispatchEvent(event);
        });
      }
    }
  }

  formatLocaleWithFlags(locale) {
    if (!locale.id) {
      return locale.text;
    }

    const customFlags = {
      "en": "flag-icon-gb", // United Kingdom flag for English
      "sr": "flag-icon-rs", // Serbia flag for Serbian
    };

    const flagClass = customFlags[locale.id] || `flag-icon-${locale.id}`;

    return $(
      `<span><span class="flag-icon ${flagClass}"></span> ${locale.text}</span>`
    );
  }

  initMultipleWithAjax() {
    $(this.element)
      .select2({
        multiple: true,
        dropdownAutoWidth: true,
        width: 'resolve',
        ajax: {
          type: 'get',
          dataType: 'json',
          data: function (params) {
            // Query parameters will be ?search=[term]&type=public
            return {
              search: params.term,
              type: 'public'
            };
          },
          processResults: function (data) {
            // Transforms the top-level key of the response object from 'items' to 'results'
            return {
              results: data
            };
          }
        }
      })
      .each(function () {
        let select_field = this;
        let options = select_field.dataset.selectedOptions;
        // Check for data-selected-options attribute and set the selected options
        // This workaround is required for multiselect fields with ajax.
        if (options) {
          JSON.parse(select_field.dataset.selectedOptions).forEach(function (data) {
            let option = new Option(data.text, data.id, false, true);
            $(select_field).append(option).trigger('change');
          });
        }
      })
      .on('change.select2', function () {
        // Required to trigger vanillaJS events
        // TODO: Move the select2 initialization to a Stimulus controller
        let event = new Event('change.select2', { bubbles: true }); // fire a native event
        this.dispatchEvent(event);
      });
  }

  submitForm() {
    $(this.element).closest('form')[0].requestSubmit();
  }

  disconnect() {
    $(this.element).select2('destroy');
  }
}
